import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import liff from "@line/liff";
import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// project imports
import accountReducer from "store/accountReducer";
import { LOGIN, LOGINFAILED, LOGOUT, USERUPDATE } from "store/actions";
import Loader from "ui-component/Loader";

import { useI18n } from "utils/localize/language";

// firebase package imports
import {
  FacebookAuthProvider,
  OAuthProvider,
  getAuth,
  getRedirectResult,
  updateProfile,
} from "firebase/auth";
import {
  addFindoutUser,
  getChannelInfo,
  getFindoutUser,
  getProviderUser,
  sendCustomVerificationEmail,
  updateFindoutUser,
} from "utils/functions/auth";

// line
let token;
let lineToken;
let uid;

//  Oauth
let OAuthAccessToken;
let providerDataUid;

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  redirectFailed: false,
  user: null,
};

let chData;
let chGroupData;
let userData;

// チャンネルID,遷移前のURL取得
console.log("window.location.href", window.location.href);
const url = new URL(window.location.href);
console.log("new Url", url);

// liff用のstateパラメータ置換
let search = decodeURIComponent(url.search).replace("?liff.state=", "");
search = decodeURIComponent(search).replace("#state", "");

// const search = decodeURIComponent(url.search).replace(
//   /?liff.state=|#state/g,
//   ""
// );

console.log("FirebaseContext search", search);

const query = new URLSearchParams(search);
console.log("FirebaseContext query", query);

const qProviderId = query.get("provider_id"); // 認証要求元のプロバイダユーザID
const qToProviderId = query.get("to_provider_id");

/////////////
// 元の文字列
// var originalString = search;

// // 正規表現パターン
// var pattern = /provider_type=(.*?)&/;

// // マッチング
// var matches = originalString.match(pattern);

// // 抜き出したい部分
// var extractedString = matches && matches[1];

// console.log("extractedString", extractedString);
// const qType = extractedString;
const qType = query.get("provider_type");
/////////////
let qId = query.get("provider_userid"); // 認証要求元のプロバイダユーザID
const redirectUri = query.get("redirectUri"); // 認証要求元のリダイレクトUri
const qProviderGroup = query.get("provider_group"); // プロバイダグループ
//line parameters
const qChannelId = query.get("channelId");
const qScanShopId = query.get("id");
const qShopName = query.get("name");
const qCheckInType = query.get("type");
const qContentsId = query.get("contents_id");
const qliffRedirectUri = query.get("liffRedirectUri");

//lineログインからリダイレクトされた際のパラメータ
let oidcProvider;

console.log("FirebaseContextでのURLパス", url);
console.log("FirebaseContextでのクエリパラメータ provider_id", qProviderId);
console.log("FirebaseContextでのクエリパラメータ provider_type", qType);
console.log("FirebaseContextでのクエリパラメータ provider_userid", qId);
console.log(
  "FirebaseContextでのクエリパラメータ provider_group",
  qProviderGroup
);
console.log("FirebaseContextでのクエリパラメータ redirectUri", redirectUri);
console.log("FirebaseContextでのクエリパラメータ channelId", qChannelId);
console.log("FirebaseContextでのクエリパラメータ id", qScanShopId);
console.log(
  "FirebaseContextでのクエリパラメータ liffRedirectUri",
  qliffRedirectUri
);

console.log(
  "FirebaseContextでのクエリパラメータ to_provider_id",
  qToProviderId
);

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });
}

let dispLang = window.navigator.language.slice(0, 2);
console.log(dispLang);
const auth = getAuth();

const FirebaseContext = createContext(null);
// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

export const FirebaseProvider = ({ children }) => {
  console.log("start FirebaseProvider");
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const i18nWrapper = useI18n();

  // findoutのID基盤に変更する
  useEffect(() => {
    (() => {
      try {
        firebase.auth().onAuthStateChanged(async (user) => {
          console.log("onAuthStateChanged user", user);
          console.log("onAuthStateChange state", state);

          // signInWithRedirect後であれば実行
          await getRedirectResult(auth)
            .then((result) => {
              console.log("getRedirectResult result", result);
              if (
                result !== null ||
                result?.operationType === "signIn" ||
                result?.providerId ===
                  process.env.REACT_APP_FACEBOOK_PROVIDERID ||
                result?.providerId === process.env.REACT_APP_LINE_PROVIDERID
              ) {
                if (user !== null) {
                  user?.multiFactor?.user?.providerData.forEach((profile) => {
                    console.log("multiFactor forEach内 profile", profile);
                    if (
                      profile?.providerId?.includes(
                        process.env.REACT_APP_FACEBOOK_PROVIDERID
                      ) ||
                      profile?.providerId?.includes(
                        process.env.REACT_APP_LINE_PROVIDERID
                      )
                    ) {
                      providerDataUid = profile.uid;
                      uid = user.uid;
                      oidcProvider = profile.providerId;

                      //facebookユーザアクセストークン取得
                      const credential =
                        OAuthProvider.credentialFromResult(result);
                      OAuthAccessToken = credential.accessToken;
                      console.log("OAuthAccessToken", OAuthAccessToken);
                      console.log("providerDataUid", providerDataUid);
                      console.log("uid", uid);
                    }
                  });
                } else {
                }
              } else {
              }
            })
            .catch(async (error) => {
              // 認証元プロバイダーメールアドレスが既に存在している場合
              if (
                error.code === "auth/account-exists-with-different-credential"
              ) {
                console.log(error.code);

                console.log("accountlink start", error.code);
                console.log("error", error);
                const pendingCred =
                  FacebookAuthProvider.credentialFromError(error);
                const email = error.customData.email;

                console.log(email);

                dispatch({
                  type: LOGINFAILED,
                  payload: {
                    user: {
                      uuid: user?.uid,
                      providerGroup: qProviderGroup,
                      providerId: qProviderId,
                      providerType: qType,
                      providerUserId: qId,
                    },
                    url: url.search,
                    errorCode: error.code,
                  },
                });
              } else {
                // 例外エラー処理発生
                console.log("exception error");
                console.log("getRedirectResult error", error);
              }
            });

          // 流入元がLINEの場合のみliff.initを実施する
          if (qType === "line") {
            await liff.init({
              liffId: process.env.REACT_APP_LIFFID,
            });

            console.log("liff ログインチェック前");
            // LINEに未認証の場合、ログイン画面にリダイレクト
            await liff.ready.then(() => {
              console.log("liff init完了後");
              if (!liff.isLoggedIn()) {
                console.log(
                  "start 2. LINEに未認証の場合、ログイン画面にリダイレクト"
                );
                liff.login({
                  redirectUri: url,
                });
              }
            });

            lineToken = liff.getDecodedIDToken();
            dispLang = window.navigator.language.slice(0, 2);
            if (!oidcProvider) {
              qId = lineToken.sub;
            }

            lineToken = liff.getDecodedIDToken();
            dispLang = window.navigator.language.slice(0, 2);
            if (!oidcProvider) {
              qId = lineToken.sub;
            }
            console.log("lineToken", lineToken);
            console.log("line Uid", qId);
          }

          if (user) {
            //dispatch前処理
            // 3.1 firebaseにログイン済みの場合、ユーザー情報を取得
            console.log("user", user);
            // API認証用IDtoken
            const idToken = await auth.currentUser.getIdToken();
            console.log("firebase認証後のidToken", idToken);
            const headers = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "",
              Authorization: `Bearer ${idToken}`,
            };
            const jwtHeaders = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "",
              Authorization: `Bearer ${process.env.REACT_APP_JWT_TOKEN}`,
            };
            // Channel情報の取得
            console.log("channelInfo取得開始");
            const chGetData = {
              provider_group: qProviderGroup,
              provider_id: qProviderId,
            };
            let dataJson = JSON.stringify(chGetData);
            console.log("channelInfo取得用", chGetData);
            const getChData = await getChannelInfo(dataJson, jwtHeaders);
            console.log("success", getChData.data);
            chData = getChData.data?.itemData;

            const users = {
              userId: user.uid,
            };
            console.log("data", users);
            let userDataJson = JSON.stringify(users);
            console.log("送信前dataJson", userDataJson);

            // await getFindoutUserByProvider(userDataJson, headers)
            await getFindoutUser(userDataJson, headers)
              .then(async (res) => {
                console.log("success ユーザ情報取得結果", res.data);
                console.log(res.data.itemData);
                if (!res.data?.itemData) {
                  console.log("start findoutユーザ作成処理");
                  /* ユーザ作成処理 start */
                  let newUserAddData;
                  const users = {
                    provider_group: qProviderGroup,
                    provider_type: qType,
                    provider_id: qProviderId,
                    provider_userid: qId,
                  };
                  console.log("data", users);
                  const userDataJson = JSON.stringify(users);

                  let userData;
                  try {
                    const result = await getProviderUser(
                      userDataJson,
                      jwtHeaders
                    );
                    // プロバイダー毎のユーザドキュメント有りパターン
                    if ("itemData" in result?.data) {
                      console.log("provider_userId登録有り");
                      console.log("getProviderUser result", result);
                      const photoURL = result.data.itemData?.provider_pic;
                      const displayName =
                        result.data.itemData?.provider_username;
                      const language =
                        result.data.itemData.provider_language === undefined
                          ? window.navigator.language.slice(0, 2)
                          : result.data.itemData.provider_language;

                      console.log("useradd前language", language);

                      // 新規ユーザドキュメントの作成処理
                      const userProfile = {
                        pic: photoURL,
                        displayName: displayName ?? "",
                        language,
                        provider: {
                          [qType]: {
                            [qProviderId]: qId,
                          },
                        },
                        provider_group: qProviderGroup,
                      };
                      const userProfileJson = JSON.stringify(userProfile);

                      console.log("addFindoutUser");
                      await addFindoutUser(userProfileJson, headers).then(
                        async (useRes) => {
                          console.log("ユーザ作成成功");
                          const userResultJson = JSON.parse(
                            JSON.stringify(useRes.data)
                          );
                          console.log("ユーザ作成レスポンス", userResultJson);
                          const users = {
                            userId: user.uid,
                          };
                          const userDataJson = JSON.stringify(users);

                          await getFindoutUser(userDataJson, headers).then(
                            (res) => {
                              console.log("findoutUser作成後取得", res.data);
                              userData = res.data.itemData;
                            }
                          );
                        }
                      );
                      console.log("addFindoutUser");
                      console.log("dispatch前");
                      dispatch({
                        type: LOGIN,
                        payload: {
                          user: {
                            uuid: user.uid,
                            providerGroup: qProviderGroup,
                            providerId: qProviderId,
                            providerType: qType,
                            providerUserId: qId,
                            email: user?.email,
                            docId: user.uid,
                            userData,
                            chData: chData,
                          },
                          token: idToken,
                          url: url.search,
                        },
                      });
                      console.log("dispatch後");
                    } else {
                      // プロバイダー毎のユーザドキュメント無しパターン
                      if (qType === "line") {
                        //lineProviderからユーザプロフィール取得
                        // // liffからパラメータを取得
                        console.log("provider_userId登録無し&&lineの場合");
                        const lineUserIdToken = lineToken;
                        console.log("lineUserIdToken", lineUserIdToken);
                        const lineUserPictureUrl = lineUserIdToken.picture;
                        const lineUserName = lineUserIdToken.name;
                        const lineLang = dispLang;
                        // 新規ユーザドキュメントの作成
                        newUserAddData = {
                          displayName: lineUserName,
                          language: lineLang.slice(0.2),
                          pic: lineUserPictureUrl,
                          provider: {
                            [qType]: {
                              [qProviderId]: qId,
                            },
                          },
                        };
                      } else {
                        // facebookからユーザプロフィール取得
                        console.log("provider_userId無しかつfacebook場合");
                        const metaToken = chData.accessToken;
                        const metaUrl = `https://graph.facebook.com/v18.0/${qProviderId}?fields=name,profile_pic&access_token=${metaToken}`;
                        const res = await axios.get(metaUrl);
                        const profileData = res.data;
                        newUserAddData = {
                          pic: profileData.profile_pic,
                          displayName: profileData.name,
                          language: window.navigator.language.slice(0, 2),
                          provider: {
                            [qType]: {
                              [qProviderId]: qId,
                            },
                          },
                          provider_group: qProviderGroup,
                        };
                      }
                      // findoutユーザ作成
                      console.log(newUserAddData);
                      const userProfileJson = JSON.stringify(newUserAddData);
                      try {
                        await addFindoutUser(userProfileJson, headers).then(
                          async (useRes) => {
                            console.log("ユーザ作成成功");
                            const userResultJson = JSON.parse(
                              JSON.stringify(useRes.data)
                            );
                            console.log("ユーザ作成レスポンス", userResultJson);
                            const users = {
                              userId: user.uid,
                            };
                            const userDataJson = JSON.stringify(users);
                            await getFindoutUser(userDataJson, headers).then(
                              (res) => {
                                console.log("findoutUser作成後取得", res.data);
                                userData = res.data.itemData;
                              }
                            );
                          }
                        );
                      } catch (e) {
                        console.log("ユーザ作成処理にてエラー発生", e);
                        if (e.statusCode === 500) {
                          // findoutのユーザ作成処理に失敗)
                          console.log(`eCode ${e.statusCode} ${e}`);
                        }
                      }
                    }

                    await i18nWrapper.changeLanguage(dispLang);

                    //ログイン画面前URLへの画面遷移処理
                    // console.log("dispatch後のpath", window.path);
                  } catch (e) {
                    console.log("ユーザ作成処理にてエラー発生", e);
                    if (e.statusCode === 500) {
                      // findoutのユーザ作成処理に失敗
                      console.log(`eCode ${e.statusCode} ${e}`);
                    }
                  }

                  /* ユーザ作成処理　end */
                } else {
                  // findoutユーザドキュメント作成済
                  console.log("findoutUser有り");
                  userData = res.data.itemData;
                  console.log("userData", userData);
                  console.log("chData", chData);

                  const newProviderData = {
                    ...userData?.provider[qType],
                  };

                  newProviderData[qProviderId] = qId;

                  console.log("紐づけ後、更新プロバイダ情報", newProviderData);

                  // 紐づけ対象のプロバイダが存在しない場合、プロバイダ紐づけ処理実行
                  console.log(
                    "プロバイダ紐づけ判定前",
                    !userData?.provider?.[qType]?.[qProviderId]?.[qId]
                  );
                  if (!userData?.provider?.[qType]?.[qProviderId]?.[qId]) {
                    console.log("プロバイダ紐づけ処理");

                    const updateUser = {
                      userId: user.uid,
                      provider: {
                        ...userData?.provider,
                        [qType]: newProviderData,
                      },
                    };
                    console.log("updateUser", updateUser);
                    const updateUserDataJson = JSON.stringify(updateUser);
                    await updateFindoutUser(updateUserDataJson, headers);
                    const res = await getFindoutUser(userDataJson, headers);
                    userData = res.data.itemData;
                  }

                  await i18nWrapper.changeLanguage(dispLang);
                  dispatch({
                    type: LOGIN,
                    payload: {
                      isLoggedIn: true,
                      user: {
                        dispLang: dispLang,
                        uuid: user.uid,
                        token: idToken,
                        providerGroup: qProviderGroup,
                        providerId: qProviderId,
                        providerType: qType,
                        providerUserId: qId,
                        email: user?.email,
                        docId: userData?.id,
                        userData,
                        // providerUserData: res?.data?.itemData,
                        providerUserData: res?.data?.itemData ?? userData, // test
                        chData,
                        OAuthAccessToken: OAuthAccessToken,
                      },
                      token: idToken,
                      url: url.search,
                    },
                  });
                }
              })
              .catch(async (e) => {
                console.log("e", e);
                await i18nWrapper.changeLanguage(dispLang);
                dispatch({
                  type: LOGOUT,
                  payload: {
                    param: {
                      providerId: qProviderId,
                      providerType: qType,
                      providerUserId: qId,
                      providerGroup: qProviderGroup,
                    },
                    url: url,
                  },
                });
              });
          } else {
            // firebase auth認証していない場合
            console.log("firebase auth　認証前");
            await i18nWrapper.changeLanguage(dispLang);
            dispatch({
              type: LOGOUT,
              payload: {
                param: {
                  providerId: qProviderId,
                  providerType: qType,
                  providerUserId: qId,
                  providerGroup: qProviderGroup,
                  // token: idToken,
                },
                url: url,
              },
            });
          }
        })();
      } catch (error) {
        //エラーハンドリング
        console.log("ログイン処理中にエラー発生");
      }
    })();
  }, [dispatch]);

  const firebaseEmailPasswordSignIn = (email, password) => {
    firebase.auth().signInWithEmailAndPassword(email, password);
  };

  // firebase auth作成
  const firebaseRegister = async (email, password) => {
    console.log("start firebaseRegister");
    try {
      const userCredencial = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      console.log("userCredencial", userCredencial);
      await sendCustomVerificationEmail(email, "testFindoutさん");
      console.log("認証メール送信完了");
    } catch (error) {
      console.log("error", error);
      switch (error.code) {
        case "auth/email-already-in-use":
          // すでに利用済み
          console.log("既に登録されているメールアドレスです。");
          break;
        default:
        // その他のエラー処理
      }
    }
    console.log("end  firebaseRegister");
  };

  const updateUserData = (user) => {
    console.log("userUpdated");
    dispatch({
      type: USERUPDATE,
      payload: {
        isLoggedIn: state.isLoggedIn,
        user: {
          dispLang: state.dispLang,
          uuid: state.user.uid,
          providerId: state.user.providerId,
          providerType: state.user.providerType,
          providerUserId: state.user.providerUserId,
          email: state.user.email,
          docId: state.user.docId,
          userData: {
            id: state.user.userData.id,
            data: {
              createAt: state.user.userData.data.createAt,
              displayName: user.name,
              language: user.language,
              pic: state.user.userData.data.pic,
              provider: state.user.userData.data.provider,
              uuid: state.user.userData.data.uuid,
              updateAt: state.user.userData.data.updateAt,
            },
          },
          providerUserData: state.user.providerUserData,
          chData: state.user.chData,
        },
        token: state.token,
        url: state.url,
      },
    });
  };

  const updateProviderUserData = (user) => {
    console.log("provideruserUpdated");
    console.log("dispatch", user);
    dispatch({
      type: USERUPDATE,
      payload: {
        isLoggedIn: state.isLoggedIn,
        user: {
          dispLang:
            user.language === undefined ? state.user.dispLang : user.language,
          uuid: state.user.uid,
          providerId: state.user.providerId,
          providerType: state.user.providerType,
          providerUserId: state.user.providerUserId,
          email: state.user.email,
          docId: state.user.docId,
          userData: state.userData,
          providerUserData: {
            channelId: state.user.providerUserData.channelId,
            createAt: state.user.providerUserData.createAt,
            lastAt: state.user.providerUserData.lastAt,
            lastEvent: state.user.providerUserData.lastEvent,
            points: state.user.providerUserData.points,
            provider_id: state.user.providerUserData.provider_id,
            provider_name:
              user.name === undefined
                ? state.user.providerUserData.provider_name
                : user.name,
            provider_pic:
              user.pic === undefined
                ? state.user.providerUserData.provider_pic
                : user.pic,
            provider_language:
              user.language === undefined
                ? state.user.providerUserData.provider_language
                : user.language,
            provider_type: state.user.providerUserData.provider_type,
            provider_userid: state.user.providerUserData.provider_userid,
            status: state.user.providerUserData.status,
            updateAt: state.user.providerUserData.updateAt,
          },
          chData: state.user.chData,
        },
        token: state.token,
        url: state.url,
      },
    });
  };

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  // const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  console.log("end FirebaseProvider");

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        dispatch,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        logout,
        resetPassword,
        updateProfile,
        updateUserData,
        updateProviderUserData,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
