import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MinimalLayout from "layout/MinimalLayout";

// Map
const Map = Loadable(lazy(() => import("views/application/map")));
const MapList = Loadable(lazy(() => import("views/application/mapList")));
const FloorMap = Loadable(lazy(() => import("views/application/floorMap")));
const FloorPlan = Loadable(lazy(() => import("views/application/floorPlan")));
// PdfViewer
const PdfViewer = Loadable(lazy(() => import("views/application/PdfViewer")));

// mountainsMap
const MountainsPlan = Loadable(
  lazy(() => import("views/application/mountainsPlan"))
);
const MountainsMap = Loadable(
  lazy(() => import("views/application/mountainsMap"))
);
const Games = Loadable(lazy(() => import("views/application/games/brain")));
const Slot = Loadable(lazy(() => import("views/application/games/slot")));
const CheckIn = Loadable(
  lazy(() => import("views/application/checkin/CheckInGeo"))
);
const Coupon = Loadable(lazy(() => import("views/application/coupon")));
const Prize = Loadable(lazy(() => import("views/application/prize")));
const AppUserAccountProfile = Loadable(
  lazy(() => import("views/application/Profile"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    { path: "/map", element: <Map /> },
    { path: "/maplist", element: <MapList /> },
    {
      path: "/pdfviewer",
      element: <PdfViewer />,
    },
    { path: "/floormap", element: <FloorMap /> },
    { path: "/floorplan", element: <FloorPlan /> },
    { path: "/mountainsplan", element: <MountainsPlan /> },
    { path: "/mountainsmap", element: <MountainsMap /> },
    { path: "/games", element: <Games /> },
    { path: "/slot", element: <Slot /> },
    { path: "/checkin", element: <CheckIn /> },
    { path: "/coupon", element: <Coupon /> },
    { path: "/prize", element: <Prize /> },
    {
      path: "/fuserprofile",
      element: <AppUserAccountProfile />,
    },
  ],
};

export default MainRoutes;
